exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-angebote-tsx": () => import("./../../../src/pages/angebote.tsx" /* webpackChunkName: "component---src-pages-angebote-tsx" */),
  "component---src-pages-anmelden-tsx": () => import("./../../../src/pages/anmelden.tsx" /* webpackChunkName: "component---src-pages-anmelden-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-nothelferkurs-tsx": () => import("./../../../src/pages/nothelferkurs.tsx" /* webpackChunkName: "component---src-pages-nothelferkurs-tsx" */),
  "component---src-pages-preise-tsx": () => import("./../../../src/pages/preise.tsx" /* webpackChunkName: "component---src-pages-preise-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */),
  "component---src-pages-vku-info-tsx": () => import("./../../../src/pages/vku-info.tsx" /* webpackChunkName: "component---src-pages-vku-info-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-service-details-tsx": () => import("./../../../src/templates/service-details.tsx" /* webpackChunkName: "component---src-templates-service-details-tsx" */)
}

